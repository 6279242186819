import React from "react"
import Layout from "../../components/Layout"
import uwuImg from "../../../static/blogs/3.jpg"

const Uwu3 = () => {
  return (
    <Layout>
      <main className={"artist-image w-100 flex-center-rw"}>
      </main>
      <section className={"artist-bio w-100"}>
        <div className={"artist-img"}>
          <img src={uwuImg} alt="aykunna artist image" />
          <a href="#" className={"follow-yt"} target="_blank">Watch on Youtube</a>
          <a href="#" className={"follow-tw"} target="_blank">Follow on Twitter</a>
        </div>
        <div className={"artist-info"}>
          <h2>Landscapes Of Joy</h2>
          <p>As the photo prints are stuck up, they say another big Hi to the
            memories and the powerful stories that are behind them. There is
            always a warm welcome behind the curtain with the crayon painted
            sheet. Postcards and toys saw her getting stronger day by day. <br/><br/>
            She began memorizing all the names of her friends one by one. When
            she eventually started to explore fields, she started feeling at home. As
            she counted butterflies, she became familiar with colours.<br/><br/>
            Jumping into mud puddles and diving into paper boats, she lived a life
            that hit happiness head-on all at once. She was free from the fear of
            self-doubt and even the thoughts that awoke her in the middle of the
            night. Is it fair that we all deserve happy adulthood that's laced with
            stories of hope and resistance similar like our childhoods?</p>
        </div>
      </section>
    </Layout>
  )
}

export default Uwu3